@import "variables";


.trainings{

    margin-bottom: 20px;
    overflow-x: hidden;

    .tabs{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex; 
        width: 100%;
        font-weight: bold;
        
        li{
          flex: 1; /* Distribute the available space evenly among list items */
          text-align: center;
          padding: 40px 10px;
          color: white;
          cursor: pointer;
          font-size: 26px;
          position: relative; 

          @media (max-width: 1076px) {
              font-size: 22px;
          }

          @media (max-width: 992px) {
              font-size: 20px;
          }

          @media (max-width: 768px) {
              font-size: 18px;
          }
        
          @media (max-width: 576px) {
              font-size: 14px;
          }

        }
      }
        
    .light-green {background-color: #61C17A;}
    .dark-green {background-color: #00AA2B;}  

    .light-purple {background-color: #BB98D9;}
    .mid-purple {background-color: #A671D4;}
    .dark-purple {background-color: #C5D82E;}
    
    .orange{background-color: $primary;}

    .gray{background-color: #707070;}
    .bg-gray{background-color: #707070;}

   
    .active::after {
      content: "";
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      height: 10px;
      background-color: white;
      z-index: 1000;
    }
    
}

      .slider {
        overflow: hidden;
      }

      .slider-content {
        display: flex;
        transition: transform 0.1s;
      }

      .slide {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 24px;
        .container{
          margin-top: 80px;
        }
      }

      .slide-1{background-color: #7DCC92;}
      
      .slide-2{background-color: #2EB951;}
      
      .slide-3{background-color: #CCB2E2;}
      
      .slide-4{background-color: #B78DDC;}
      
      .slide-5{background-color: $primary;}

      .my-container {
        position: relative;
        background-color: white;
        height: min-content; /* Minimum height is determined by content */
        width: 100%;
        min-height: 370px !important;


        @media (max-width: 992px) {
            min-height: fit-content !important;
            margin-top: 50px;
        }

        h1{
          font-weight: bold;
          color: #4E4D4D;
        }

        p{
          color: #4E4D4D;
          text-align: right;
          font-size: 20px;
        }
        p.fs-14{
          font-size: 14px;
        }
        ul{
          direction: rtl;
          text-align: right;
          padding-left: 20px;
          margin-right: 20px;
        }
        li{
          color: #4E4D4D;
          font-size: 20px;
        }
      }

      .title-1 {
        position: relative;
        color: white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        margin-top: -20px;
        font-size: 26px;
        font-weight: bold;
      }

      
      .title-1::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 270px;
        height: 100%; /* Adjust the height to 100% */
        background-color: #61C17A;
        transform: skew(340deg);
        z-index: -1;
        border: 1px solid white;
      }

      .title-1::after {
        content: "";
        position: absolute;
        top: 1px;
        right: 280px;
        width: 6px;
        height: 90%;
        background-color: #61C17A;
        transform: skewY(340deg) rotate(25deg);
      }

      .title-2 {
        position: relative;
        color: white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        margin-top: -20px; 
                font-size: 26px;
        font-weight: bold;
      }
      
      .title-2::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 270px;
        height: 100%; 
        background-color: #00AA2B;
        transform: skew(340deg);
        z-index: -1;
        border: 1px solid white;
        opacity: 1;
      }
      .title-2::after {
        content: "";
        position: absolute;
        top: 1px;
        right: 280px;
        width: 6px;
        height: 90%;
        background-color: #00AA2B;
        transform: skewY(340deg) rotate(25deg);
      }
     
     
      .title-3 {
        position: relative;
        color: white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        margin-top: -20px; 
                font-size: 26px;
        font-weight: bold;
      }
      
      .title-3::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 270px;
        height: 100%; 
        background-color: #BB98D9;
        transform: skew(340deg);
        z-index: -1;
        border: 1px solid white;
        opacity: 1;
      }
      .title-3::after {
        content: "";
        position: absolute;
        top: 1px;
        right: 280px;
        width: 6px;
        height: 90%;
        background-color: #BB98D9;
        transform: skewY(340deg) rotate(25deg);
      }
      
      .title-4 {
        position: relative;
        color: white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        margin-top: -20px; 
                font-size: 26px;
        font-weight: bold;
      }
      
      .title-4::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 270px;
        height: 100%; 
        background-color: #A671D4;
        transform: skew(340deg);
        z-index: -1;
        border: 1px solid white;
        opacity: 1;
      }
      .title-4::after {
        content: "";
        position: absolute;
        top: 1px;
        right: 280px;
        width: 6px;
        height: 90%;
        background-color: #A671D4;
        transform: skewY(340deg) rotate(25deg);
      }

      .title-5 {
        position: relative;
        color: white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        margin-top: -20px; 
                font-size: 26px;
        font-weight: bold;
      }
      
      .title-5::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 270px;
        height: 100%; 
        background-color: #8E46CD;
        transform: skew(340deg);
        z-index: -1;
        border: 1px solid white;
        opacity: 1;
      }
      .title-5::after {
        content: "";
        position: absolute;
        top: 1px;
        right: 280px;
        width: 6px;
        height: 90%;
        background-color: #8E46CD;
        transform: skewY(340deg) rotate(25deg);
      }

      .styled-list-teal{
        margin-right: 20px;
        list-style: none;
        font-size: 20px !important;
        li::before {
          content: "\2022"; 
          color: #59B0B8; 
          font-weight: bold; 
          display: inline-block; 
          width: 1em; 
        }
      }
      
      .styled-list-green{
        font-size: 20px !important;
        margin-right: 20px;
        list-style: none;
        li::before {
          content: "\2022"; 
          color: #009673; 
          font-weight: bold; 
          display: inline-block; 
          width: 1em; 
        }
      }
      
      .row {
        display: flex;
      }
      
      .col-12.col-md-4 {
        display: flex;
        flex-direction: column;
      }
      
      .col-12.col-md-4 .my-container {
        flex-grow: 1;
      }

      .icons{
        margin-left: 10px;
        margin-bottom: 4px;
      }
      .icons-lg{
        width: 25px;
      }
      
      .icons-md{
        width: 20px;
      }

      .register-button      
      {
        background: white;
        border: 1px solid #707070;
        border-radius: 10px;
        height: 50px;
        width: 15% !important;
        margin-left: 12px;
        font-weight: bold;
      }


      .button-1{
        color: #61C17A;
        margin-top: 50px !important;
        padding-top: 5px;
      }

      .button-2{color: #00AA2B;}

      .button-3{color: #BB98D9;}

      .button-4{color: #A671D4;}
      
      .button-5{color: #8E46CD;}

      @media (max-width: 1200px) {
        .register-button{
          width: 20% !important;
        }
      }


      @media (max-width: 992px) {
        .register-button{
          width: 30% !important;
        }
      }

      @media (max-width: 516px) {
        .register-button{
          width: 40% !important;
        }
          .title-1, .title-2, .title-3, .title-4, .title-5{
            font-size: 22px;
          }
      }
      
      @media (max-width: 400px) {
        .register-button, .button-1, .button-2, .button-3, .button-4, .button-5{
          width: 40% !important;
          font-size: 16px;
          padding-top: 10px !important;
        }
      
      }


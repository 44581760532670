@import "variables";

.course-detail-wrapper {
  position: relative;
  min-height: 45vh;
  background-image: linear-gradient(
      to bottom,
      rgba(8, 2, 7, 0.3),
      rgba(8, 2, 7, 0.4)
    ),
    url("../images/landing-bg-mob.png");
  background-size: cover;
  background-position: center center;
  padding-top: 100px;

  .course-detail-header {
    height: calc(45vh - 100px);
    h1 {
      font-size: 2rem;
      color: white;
    }
  }
}

@media (min-width: 600px) {
  .course-detail-wrapper {
    background-image: linear-gradient(
        to bottom,
        rgba(8, 2, 7, 0.3),
        rgba(8, 2, 7, 0.4)
      ),
      url("../images/landing-bg-hi.png");
  }
}
.course-detail {
  h2 {
    color: $secondary;
    text-align: end;
  }
  .course-detail-box {
    border: 1px solid $gray;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 20px 20px;

    @media (min-width: 768px) {
      padding: 20px 40px;
    }

    h5 {
      font-size: 15px;
    }
  }
  .course-detail-date-badge {
    border: 1px solid $gray;
    .purple-box {
      background-color: $primary;
      padding: 20px 30px 20px 30px;
      color: white;
    }
    .green-box {
      background-color: $secondary;
      color: white;
      padding: 20px;
    }
    h5 {
      font-size: 15px;
    }
    .circle {
      background-color: white;
      padding: 10px;
      margin: -10px -20px;
      border-radius: 50%;
    }
  }
}

.course-detail-content {
  border: 1px solid $primary;

  .course-content-heading {
    background-color: $primary;
    padding: 20px;
  }
  .course-content-body {
    background-color: #9968cf;
  }

  .course-enroll-btn {
    position: relative;
    border: none;
    background-color: transparent;
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 3px 10px;
    font-size: 12px;
    margin-left: 20px;
    font-weight: bold;
  }

  .course-enroll-btn:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: 100%; /* Adjust the height to 100% */
    background-color: $secondary;
    transform: skew(340deg);
    z-index: -1;
  }

  .course-enroll-btn:after {
    content: "";
    position: absolute;
    top: 1px;
    right: 95px;
    width: 6px;
    height: 90%;
    background-color: $secondary;
    transform: skewY(340deg) rotate(25deg);
  }
}

.schedule-table-wrapper {
  margin-top: 220px;
}
@media screen and (min-width: 768px) {
  .schedule-table-wrapper {
    margin-top: 150px;
  }
}

.course-detail-logos-section {
  h2 {
    color: $secondary;
  }
  .course-detail-logos {
    background-color: #fff;
    // background-color: #d3d3d3;
  }
  .course-detail-company-logo {
    height: 150px;
    // overflow: hidden;
  }
  .course-detail-company-logo img {
    height: 150px;
  }
  .course-detail-company-gea img {
    width: 240px;
  }

  @media (min-width: 768px) {
    // .course-detail-company-logo {
    //   height: 180px;
    // }
    // .course-detail-company-logo img {
    //   height: 180px;
    // }
    // .course-detail-company-gea img {
    //   width: 0px;
    // }
  }
}

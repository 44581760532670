.initiative{
    padding-right: 120px;
    padding-bottom: 80px;
    h1{
        font-weight: bold;
        color: #161616;
    }
    
    h2{
        font-weight: normal;
        color: grey;
    }
    
    h3{
        margin-top: 40px;
        margin-bottom: 30px;
        font-weight: normal;
        color: #4E4D4D;
    }

    h4{
        margin-top: 10px;
        font-weight: bold; 
    }

    p{
        font-weight: bold;
        font-size: 18px;
        color: #4D4D4D;
        line-height: 30px;
    }

    ul{
        padding-right: 15px;

    }

    .text-div{
        padding-top: 40px;
    }

    .initiative-img{
        margin-left: -70px;
        width: 500px;
    }
    
    
    .infographic{
        width: 100px;
    }
    
    @media (max-width: 1475px) {
        .initiative-img{
            margin-left: 0px;
        }
    }
    
    @media (max-width: 1092px) {
        .initiative-img{
            width: 500px;
            margin-left: 60px;
        }
    }
    
    @media (max-width: 992px) {
        padding-right: 40px;
        .initiative-img{
            width: 400px;
        }
    }
    
    @media (max-width: 992px) {
        padding-right: 10px;
    }
    
    @media (max-width: 768px) {
        padding-right: 0px;
        
        .initiative-img{
            width: 450px;
            margin-left: -300px;
        }
        
        .text-div{
            padding-top: 0;
        }
    }
    
    @media (max-width: 768px) {
        .initiative-img{
            
            margin-left: -100px;
        }

    }

}

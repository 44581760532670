.landing-area {
  height: 100vh;
  padding-top: 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    url("../images/landing-bg-hi.png");
  background-size: cover;
  background-position: bottom;

  .img-div {
    width: min-content;
  }

  .container-fluid {
    position: relative;
    height: 100vh;

    .hc-logo {
      width: 25%;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media (max-width: 1400px) {
      .hc-logo {
        width: 25%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (max-width: 1400px) {
      .hc-logo {
        position: absolute;
        width: 30%;
        // top: 450px;
      }
    }

    // @media (max-width: 1100px) {

    //     .overlay-img{
    //         top: -350px;
    //     }

    //     .hc-logo{
    //         width: 30%;
    //         top: 50px;
    //         left: 37%;
    //     }
    // }

    @media (max-width: 992px) {
      .hc-logo {
        width: 40%;
      }
    }

    @media (max-width: 730px) {
      .hc-logo {
        width: 50% !important;
      }
    }

    @media (max-width: 600px) {
      background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
        url("../images/landing-bg-mob.png");
      background-size: cover;
      background-position: center;

      .hc-logo {
        width: 70% !important;
        top: 40%;
      }
    }
  }

  .barnamg-logo {
    width: 200px;
  }

  .vision-2030-logo {
    padding-right: 30px;
    width: 150px;
    border-right: 2px solid white;
  }

  .gea-logo {
    margin-left: 30px;
    width: 320px;
  }

  .full-size-row {
    height: calc(100vh - 150px);
  }

  .full-size-logo {
    height: 200px;
    width: auto;
  }

  @media (max-width: 900px) {
    .container-fluid {
      padding: 0 20px;
    }

    .barnamg-logo {
      width: 150px;
    }

    .vision-2030-logo {
      padding-right: 20px;
      width: 150px;
      border-right: 2px solid white;
    }

    .gea-logo {
      margin-left: 20px;
      width: 250px;
    }

    .full-size-logo {
      height: 150px;
    }
  }

  @media (max-width: 460px) {
    .barnamg-logo {
      width: 150px;
      margin-bottom: 20px;
    }

    .vision-2030-logo {
      padding-right: 0;
      width: 150px;
      border-right: 0px;
    }

    .gea-logo {
      margin-top: 25px;
      margin-left: 0;
      width: 280px;
    }

    .full-size-logo {
      height: 150px;
    }

    .full-size-row {
      height: calc(100vh - 400px);
    }
  }
}

@import "variables";

.home-bg {
  background-color: #f5f5f5;
}
.home-wrapper {
  position: relative;
  min-height: 100vh;

  .home-signin-btn {
    position: absolute;
    top: 5px;
    left: 20px;
    z-index: 222;
  }
  .home-signin-btn button {
    padding: 10px 20px;
    color: $primary;
    border: 2px solid $secondary;
    border-radius: 10px;
    cursor: pointer !important;
  }

  .home-wrapper-big-green-bar {
    position: absolute;
    top: 62%;
    left: 0;
    background-color: $secondary;
    width: 85%;
    height: 45px;
  }
  @media (min-width: 568px) {
    .home-wrapper-big-green-bar {
      top: 62%;
      width: 65%;
      height: 60px;
    }
    .home-signin-btn {
      top: 100px;
      left: 50px;
    }
  }
  @media (min-width: 768px) {
    .home-signin-btn {
      top: 100px;
      left: 50px;
    }
  }
  @media (min-width: 992px) {
    .home-wrapper-big-green-bar {
      top: 62%;
      width: 65%;
      height: 60px;
    }
    .home-signin-btn {
      position: absolute;
      top: 160px;
      left: 50px;
    }
    .home-signin-btn button {
      padding: 15px 30px;
      color: $primary;
      border: 2px solid $secondary;
      border-radius: 10px;
      // cursor: pointer !important;
    }

   
  }


  @media (min-width: 1200px) {
    .home-wrapper-big-green-bar {
      top: 51%;
    }
  }

  .home-header {
    height: calc(100vh - 100px);
    margin-top: 100px !important;

    .home-header-top-green-bar {
      background-color: #fe8901;
      width: 80px;
      height: 10px;
    }

    .home-header-main-img {
      position: relative;
      z-index: 2;
      // overflow: hidden;
      height: 350px;
      text-align: left;
      
    }
    .home-header-main-img img {
      height: 300px;
      object-fit: cover;
    }
  }
  h1 {
    font-size: 1.8rem;
    font-weight: bolder;
    white-space: nowrap;
  }
  .home-header-btn {
    background-color: #FE8901;
    color: white;
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 30px;
    box-shadow: 0px 3px 6px #00000029;

    &:hover {
      box-shadow: 0px 3px 6px #00000010;
    }
  }
  @media (min-width: 300px) {
    h1 {
      font-size: 1.4rem;
    }
    .home-header {

      .home-header-top-green-bar {
        margin-top: 120px !important;
        width: 120px;
        height: 13px;
      }
    }
  }

  @media (min-width: 350px) {
    
    .home-header {

      .home-header-top-green-bar {
        margin-top: 100px !important;
        width: 120px;
        height: 13px;
      }
    }
  }


  @media (min-width: 400px) {

    h1 {
      font-size: 1.8rem;
    }
  }


  @media (min-width: 568px) {
    .home-header {
      margin-top: 0 !important;
      .home-header-top-green-bar {
        margin-top: 150px !important;
        width: 120px;
        height: 13px;
      }
      .home-header-main-img {
        display: flex;
        justify-content: start;
        // margin-top: -150px !important;
        height: 300px;
      }
      .home-header-main-img img {
        height: 300px;
      }
    }
    h1 {
      font-size: 2.5rem;
      font-weight: bolder;
      white-space: nowrap;
    }
    .home-header-btn {
      padding: 12px 35px;
      margin-top: 30px;
    }
  }
  
  @media (min-width: 768px) {
    .home-header {
      .home-header-top-green-bar {
        margin-top: 50px;
      }
      .home-header-main-img {
        // margin-top: 200px !important;
        height: 350px;
      }
      .home-header-main-img img {
        height: 350px;
      }
    }
    .home-header-btn {
      padding: 15px 45px;
      font-size: 20px;
      margin-top: 30px;
    }
  }
  @media (min-width: 768px) {
    .home-header {
      .home-header-top-green-bar {
        margin-top: 200px !important;
      }
      .home-header-main-img {
        // margin-top: 200px !important;
        height: 350px;
      }
      .home-header-main-img img {
        height: 500px;
        margin-top: -100px;
      }
    }
    .home-header-btn {
      padding: 15px 45px;
      font-size: 20px;
      margin-top: 30px;
    }
  }
  
  @media (min-width: 992px) {
    .home-header {
      .home-header-main-img {
        height: 450px;
        margin-top: 50px !important;
      }
      .home-header-main-img img {
        height: 550px;
        margin-top: 0px;
      }
    }
    h1 {
      font-size: 3rem;
    }
    .home-header-btn {
      padding: 15px 45px;
      font-size: 20px;
      margin-top: 30px;
    }
  }
  
  @media (min-width: 1200px) {
    .home-header {

      .home-header-main-img img {
        margin-top: 50px;
      }
    }
    h1 {
      font-size: 3rem;
    }
  }



  @media (min-width: 1300px) {
    .home-header {
      .home-header-top-green-bar {
        margin-top: 0 !important;
      }
      .home-header-main-img {
        height: 550px;
      }
      .home-header-main-img img {
        height: 550px;
      }
    }
    h1 {
      font-size: 3rem;
    }
  }
  @media (min-width: 1350px) {
    .home-header {
      .home-header-top-green-bar {
        margin-top: 0 !important;
      }
      .home-header-main-img {
        margin-top: 100px !important;
        height: 600px;
      }
      .home-header-main-img img {
        height: 600px;
      }
    }
    h1 {
      font-size: 3.3rem;
    }
  }
  @media (min-width: 1400px) {
    .home-header {
     
      .home-header-main-img {
        margin-top: 100px !important;
        height: 700px;
      }
      .home-header-main-img img {
        height: 700px;
      }
    }
    h1 {
      font-size: 3.3rem;
    }
  }
}


.course-heading-img img {
  height: 70px;
  object-fit: cover;
}
.schedule-heading-img {
  position: relative !important;
}
.schedule-heading-img img {
  position: relative;
  z-index: 5 !important;
}
.schedule-green-bar {
  padding: 4.5px 38px;
  background-color: $secondary;
  position: absolute;
  top: 31px;
  left: -13px;
  z-index: 1;
}

@media (min-width: 600px) {
  .home-wrapper {
    // background-image: linear-gradient(
    //     to bottom,
    //     rgba(8, 2, 7, 0.3),
    //     rgba(8, 2, 7, 0.4)
    //   ),
    //   url("../images/landing-bg-hi.png");
  }
}
.search-container {
  padding: 30px;
  min-height: 180px;
  background-color: white;
  color: $primary;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 1100px;
  z-index: 1;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  h3 {
    min-width: 180px;
  }
  .home-select {
    width: 100%;
    min-width: 100px;
    max-width: 180px;
    box-shadow: 0px 3px 6px #00000029;
    color: $light-gray;
  }
  .select__indicators,
  .select__indicator-separator,
  .select__indicator {
    display: none !important;
  }
  .select__single-value {
    color: $light-gray;
  }
  .home-search-btn {
    background-color: $primary;
    color: white;
    padding: 5px 30px;
    border-color: transparent;
    border-radius: 8px;
    font-size: 20px;
    box-shadow: 0px 3px 6px #00000029;

    &:hover {
      box-shadow: 0px 3px 6px #00000010;
    }
  }
}

@media (min-width: 768px) {
  .search-boxes {
    flex: 3;
  }
  .search-select {
    width: 200px;
  }
}

.courses-list {
  h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  // @media (min-width: 769px) {
  //   h2 {
  //     margin-top: 150px;
  //   }
  // }
  @media (min-width: 730px) {
    .home-container {
      max-width: 730px !important;
    }
    .course-cards {
      justify-content: end !important;
    }
  }
  @media (min-width: 1094px) {
    .home-container {
      max-width: 1094px !important;
    }
  }

  .course-card {
    height: inherit;
    // box-shadow: 0px 3px 6px #00000029;

    .course-card-img {
      height: 250px;
      overflow: hidden;

      img {
        width: 100%;
        height: 250px;
        object-fit: cover;
      }
    }
    .course-card-content {
      // border-bottom: 1px solid $secondary;
      // border-left: 1px solid $secondary;
      // border-right: 1px solid $secondary;
      background-color: #fff;
      height: 200px;
      padding: 20px 20px 10px 20px;

      .card-label {
        font-size: 0.8rem;
      }
      .card-label-icon {
        width: 16px;
        color: $light-gray;
      }
      .card-light-text {
        color: $light-gray;
        font-size: 18px;
      }
      .home-card-btn {
        padding: 3px 10px;

        &:hover {
          box-shadow: 0px 3px 6px #00000010;
        }
      }

      .free-card-btn {
        border: 1px solid darkorange;
        color: darkorange;
        padding: 3px 20px;
        margin-bottom: 10px;
      }

      .regular-btn {
        border: none;
        box-shadow: 0px 3px 6px #00000029;
        color: white;
        z-index: 1;
        padding-right: 3px 10px;
        font-size: 12px;
        margin-left: 20px;
        background-color: $primary;
        padding: 5px 20px;
        margin-bottom: 10px;
      }
    }
  }
  @media (min-width: 730px) {
    .course-card {
      width: "340px";

      .course-card-img {
        img {
          width: 340px;
        }
      }
    }
  }

  .course-card-divider {
    background-color: $gray-bg;
    padding: 2px 0 !important;
  }
  .register-card-btn {
    border: none;
    width: 100%;
    background-color: $secondary;
    color: #fff;
    padding: 8px 0;
  }

  .swiper-button-disabled {
    color: $primary !important;
    background-color: #fff !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 50%;
    font-size: 20px !important;
    width: 40px;
    height: 40px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    color: #fff;
    background-color: $secondary;
    border-radius: 50%;
    font-size: 20px !important;
    width: 40px;
    height: 40px;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    border-radius: 50%;
    font-size: 20px !important;
    // width: 20px !important;
    // height: 20px !important;
  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    position: absolute;
    z-index: 99999;
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
}

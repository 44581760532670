.gallery-questions{
  
  .previous-event{
    
    img{
      border-bottom: 10px solid #00AA2B;
    }

    .details{
      padding: 30px 20px;
      border: 1px solid #868686;
      margin-bottom: 50px;
      background-color: white;
      button{

        font-family: 'thesans-plain, sans-serif';
        color: white;
        padding: 5px 30px;
        border: none;
      }
    }

    &::before{
      content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50px;
        height: 200px;
        background-color: #8E46CD;
        z-index: -1; /* To place it behind the div */
    }

    .overlay {
      position: absolute;
      top: 50px;
      right: 50px;
      background: rgba(255, 255, 255, 0.5);
      padding: 5px 25px;
      color: white;
      font-weight: bold;
      font-size: 18px;
    }

  }
    .text-underline{
        text-decoration: underline;
    }

    .menu-row {
        width: 100%;
        background-color: #EB2354;
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        padding: 0;
    }
    
    .list {
        list-style-type: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0;
        padding: 40px 90px;
    }
    
    .list li {
        cursor: pointer;
        font-weight: bold;
        font-size: 22px;
        color: white;
      }
      
      .list li:first-child {
        margin-right: 30px;
      }

      .slide{
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        height: fit-content;

      }


      .circle-graphic-2{
        position: absolute;
        bottom: -500px;
        width: 30%;
        z-index: -1;
        right: -80px;
      }
    }

  .questions{

    margin-top: 50px;
    margin-bottom: 150px;

    .row{
        text-align: right;
        background-color: #F5F5F5;
        padding: 40px 10px;
        border-right: 10px solid #8e46cd;
      }


      .question{
        font-weight: bold;
        color: #4E4D4D;
        font-size: 24px !important;
      }

      
      .answer{
        color: #4E4D4D;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-bottom: 1px solid gray;
        font-size: 20px;
      }
    }


.gallery-graphics{



    .top-right{
      position: absolute;
      top: -63px;
      width: 15%;
      z-index: -1;
      right: -300px;
    }

    .top-left{
      position: absolute;
      top: -55px;
      width: 20%;
      z-index: -1;
      left: -300px;
    }

    .bottom-right{
      position: absolute;
      bottom: -70px;
      width: 15%;
      z-index: -1;
      right: -300px;
    }

    .bottom-left{
      position: absolute;
      bottom: -40px;
      width: 20%;
      z-index: -1;
      left: -300px;
    }

  @media (max-width: 1800px) {

    .top-right{
      width: 15% !important;
      right: -170px !important;
    }

    .top-left{
      width: 20% !important;
      left: -170px !important;
    }

    .bottom-right{
      width: 15% !important;
      right: -170px !important;
    }

    .bottom-left{
      width: 20% !important;
      left: -170px !important;
    }
  }

  @media (max-width: 1500px) {

    .top-right{
      width: 15% !important;
      right: -95px !important;
    }

    .top-left{
      width: 20% !important;
      left: -100px !important;
    }

    .bottom-right{
      width: 15% !important;
      right: -100px !important;
    }

    .bottom-left{
      width: 20% !important;
      left: -95px !important;
    }

  }

  @media (max-width: 1400px) {

    .top-right{
      width: 15% !important;
      right: -130px !important;
    }

    .top-left{
      width: 20% !important;
      left: -130px !important;
    }

    .bottom-right{
      width: 15% !important;
      right: -130px !important;
    }

    .bottom-left{
      width: 20% !important;
      left: -130px !important;
    }

  }
  @media (max-width: 1200px) {

    
    .top-right{
      right: -130px !important;
    }

    .top-left{
      left: -120px !important;
    }

    .bottom-right{
      right: -120px !important;
    }

    .bottom-left{
      left: -120px !important;
    }

  }



  @media (max-width: 1100px) {

    
    .top-right{
      right: -70px !important;
    }

    .top-left{
      left: -80px !important;
    }

    .bottom-right{
      right: -62px !important;
    }

    .bottom-left{
      bottom: -118px !important;
      left: -62px !important;
    }

  }

  @media (max-width: 992px) {

    
    .top-right{
      right: -130px !important;
    }

    .top-left{
      left: -135px !important;
    }

    .bottom-right{
      right: -132px !important;
    }

    .bottom-left{
      bottom: -18px !important;
      left: -132px !important;
    }

  }

  @media (max-width: 940px) {

    
    .top-right{
      right: -100px !important;
    }

    .top-left{
      left: -110px !important;
    }

    .bottom-right{
      bottom: -40px !important;
      right: -90px !important;
    }

    .bottom-left{
      bottom: -18px !important;
      left: -90px !important;
    }

  }

  @media (max-width: 830px) {

    
    .top-right{
      right: -55px !important;
    }

    .top-left{
      left: -70px !important;
    }

    .bottom-right{
      bottom: -40px !important;
      right: -60px !important;
    }

    .bottom-left{
      bottom: -18px !important;
      left: -80px !important;
    }

  }

  @media (max-width: 768px) {

    
    .top-right{
      width: 25% !important;
      right: -110px !important;
    }

    .top-left{
      width: 30% !important;
      left: -110px !important;
    }
    
    .bottom-right{
      width: 25% !important;
      right: -110px !important;
    }
    
    .bottom-left{
      width: 35% !important;
      left: -110px !important;
    }

  }

  @media (max-width: 700px) {

    
    .top-right{
      width: 22% !important;
      right: -80px !important;
    }

    .top-left{
      width: 30% !important;
      left: -90px !important;
    }
    
    .bottom-right{
      width: 25% !important;
      right: -90px !important;
    }
    
    .bottom-left{
      width: 35% !important;
      left: -80px !important;
    }

  }

  @media (max-width: 550px) {

    
    .top-right{
      width: 20% !important;
      right: 0px !important;
    }

    .top-left{
      width: 25% !important;
      left: 0px !important;
    }
    
    .bottom-right{
      width: 20% !important;
      right: 0px !important;
    }
    
    .bottom-left{
      width: 25% !important;
      left: -10px !important;
    }

  }    }



.questions-graphics{



  .top-right{
    position: absolute;
    top: -100px;
    width: 15%;
    z-index: -1;
    right: -300px;
  }

  .top-left{
    position: absolute;
    top: -90px;
    width: 20%;
    z-index: -1;
    left: -300px;
  }

  .bottom-right{
    position: absolute;
    bottom: -200px;
    width: 15%;
    z-index: -1;
    right: -300px;
  }

  .bottom-left{
    position: absolute;
    bottom: -180px;
    width: 20%;
    z-index: -1;
    left: -300px;
  }

@media (max-width: 1800px) {

  .top-right{
    width: 15% !important;
    right: -200px !important;
  }

  .top-left{
    width: 20% !important;
    left: -200px !important;
  }

  .bottom-right{
    width: 15% !important;
    right: -200px !important;
  }

  .bottom-left{
    width: 20% !important;
    left: -200px !important;
  }
}

@media (max-width: 1400px) {

  .top-right{
    width: 15% !important;
    right: -130px !important;
  }

  .top-left{
    width: 20% !important;
    left: -130px !important;
  }

  .bottom-right{
    width: 15% !important;
    right: -130px !important;
  }

  .bottom-left{
    width: 20% !important;
    left: -130px !important;
  }


}
@media (max-width: 1200px) {

  
  .top-right{
    right: -120px !important;
  }

  .top-left{
    left: -120px !important;
  }

  .bottom-right{
    right: -120px !important;
  }

  .bottom-left{
    left: -120px !important;
  }

}



@media (max-width: 1100px) {

  
  .top-right{
    right: -70px !important;
  }

  .top-left{
    left: -70px !important;
  }

  .bottom-right{
    right: -70px !important;
  }

  .bottom-left{
    bottom: -170px !important;
    left: -70px !important;
  }

}

@media (max-width: 992px) {

  
  .top-right{
    right: -130px !important;
  }

  .top-left{
    left: -135px !important;
  }

  .bottom-right{
    right: -132px !important;
  }

  .bottom-left{
    bottom: -170px !important;
    left: -132px !important;
  }

}

@media (max-width: 940px) {

  
  .top-right{
    right: -110px !important;
  }

  .top-left{
    left: -110px !important;
  }

  .bottom-right{
    bottom: -175px !important;
    right: -110px !important;
  }

  .bottom-left{
    bottom: -170px !important;
    left: -110px !important;
  }

}

@media (max-width: 830px) {

  
  .top-right{
    right: -55px !important;
  }

  .top-left{
    left: -70px !important;
  }

  .bottom-right{
    right: -60px !important;
  }

  .bottom-left{
    left: -60px !important;
  }

}

@media (max-width: 768px) {

  
  .top-right{
    width: 25% !important;
    right: -110px !important;
  }

  .top-left{
    width: 30% !important;
    left: -110px !important;
  }
  
  .bottom-right{
    width: 25% !important;
    right: -110px !important;
  }
  
  .bottom-left{
    width: 35% !important;
    left: -110px !important;
  }

}

@media (max-width: 700px) {

  
  .top-right{
    width: 22% !important;
    right: -80px !important;
  }

  .top-left{
    width: 30% !important;
    left: -90px !important;
  }
  
  .bottom-right{
    width: 25% !important;
    right: -90px !important;
  }
  
  .bottom-left{
    width: 35% !important;
    left: -80px !important;
  }

}

@media (max-width: 550px) {

  
  .top-right{
    width: 20% !important;
    right: 0px !important;
  }

  .top-left{
    width: 25% !important;
    left: 0px !important;
  }
  
  .bottom-right{
    width: 20% !important;
    right: 0px !important;
  }
  
  .bottom-left{
    width: 25% !important;
    left: -10px !important;
  }

}    }



@import "variables";
.contact-wrapper {
  min-height: 10vh;
  padding-top: 100px;

  .contact-heading {
    height: calc(45vh - 100px);
  }

  h1 {
    font-size: 1.5rem;
    color: white;
    margin-right: 40px;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 2.5rem;
      margin-right: 50px;
    }
  }
}

.form-wrapper {
  border: 1px solid $secondary;
  border-radius: 10px;
  padding: 15px 20px 30px 20px;

  @media (min-width: 480px) {
    padding: 15px 40px 30px 40px;
  }

  .label {
    font-size: 20px;
    margin-bottom: 5px;
  }
  textarea::placeholder,
  .input-text::placeholder {
    color: $light-gray;
  }
  // .contact-form-btn {
  //   background-color: $secondary;
  //   color: white;
  //   border: none;
  //   border-radius: 10px;
  //   box-shadow: 0px 3px 6px #00000029;
  //   padding: 8px 30px;
  // }
  .contact-form-btn {
    background-color: $secondary;
    color: white;
    padding: 5px 15px;
    border-color: transparent;
    border-radius: 8px;
    font-size: 20px;
    margin-top: 30px;
    box-shadow: 0px 3px 6px #00000029;

    &:hover {
      box-shadow: 0px 3px 6px #00000010;
    }
  }
  .input-textarea {
    height: 200px;
    border: 1px solid $secondary !important;
    border-radius: 5px;
    outline: none;
  }
  textarea:focus,
  .input-text:focus {
    box-shadow: none;
  }
}

.contact-map-section {
  h2 {
    border-bottom: 1px solid $primary;
  }
  .contact-map {
    border: 1px solid $primary;
    border-radius: 10px;
    overflow: hidden;
    height: 450px;
  }
}
.contact-info-section {
  h2 {
    border-bottom: 1px solid $primary;
  }
  .contact-info {
    background-color: $secondary;
    color: white;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px 0;
  }
  // .info-box {
  //   height: 120px;
  // }
  .info-icon {
    width: 25px;
    color: white;
  }
  h5 {
    font-size: 25px;
  }
  p {
    font-size: 18px;
  }

  @media (min-width: 480px) {
    p {
      font-size: 20px;
    }
  }

  .info-address {
    max-width: 200px;
    text-align: start;
  }

  @media (min-width: 480px) {
    .info-address {
      max-width: 280px;
    }
  }
  .info-social {
    border: 1px solid white;
    padding: 10px;
    cursor: pointer;
  }
  .info-social-icon {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 480px) {
    .info-social-icon {
      width: 30px;
      height: 30px;
    }
  }
}

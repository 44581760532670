@import "variables";
.profile-wrapper {
  position: relative;
  min-height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(8, 2, 7, 0.5),
      rgba(8, 2, 7, 0.6)
    ),
    url("../images/landing-bg-mob.png");
  background-size: cover;
  background-position: center center;
  padding-top: 100px;

  .profile-heading {
    width: 90%;

    h1 {
      font-size: 30px;
    }
  }
  .profile-form {
    width: 90% !important;
  }
  @media (min-width: 480px) {
    .profile-heading {
      width: 75%;
    }
    .profile-form {
      width: 75% !important;
    }
  }
  @media (min-width: 768px) {
    .profile-heading {
      width: 35%;
    }
    .profile-form {
      width: 35% !important;
    }
  }

  .profile-edit-icon {
    width: 25px;
    height: 25px;
    transform: rotateZ(270deg);
    margin: -20px 20px 20px -25px;
    cursor: pointer;
  }
  .input-text::placeholder {
    color: $light-gray;
  }
  .input-text:disabled {
    background-color: rgb(249, 247, 247);
  }
  .profile-actions {
    margin: -20px 20px 20px -25px;
    .action-icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
}

@media (min-width: 600px) {
  .profile-wrapper {
    background-image: linear-gradient(
        to bottom,
        rgba(8, 2, 7, 0.3),
        rgba(8, 2, 7, 0.4)
      ),
      url("../images/landing-bg-hi.png");
  }
}

.profil-list-btn {
  width: 70px;
  cursor: pointer;
}

.modal-dialog {
  border-radius: 10px !important;
  overflow: hidden;

  .modal-question-header {
    background-color: $secondary;
    padding: 20px;
  }
  .modal-main {
    padding: 40px 20px;
  }
  @media (min-width: 768px) {
    .modal-main {
      padding: 40px;
    }
  }
  h4 {
    width: 150px;
  }
  .question {
    padding: 10px 20px;
    width: 100px;
    border-radius: 10px;
    border: 1px solid $secondary;

    p {
      color: $light-gray;
    }
  }
  .question-checkboxes {
    label {
      font-size: 12px;
      margin-bottom: 0 !important;
    }
    .form-check-input {
      border: 1px solid $secondary;
      height: 20px;
      width: 40px;
      cursor: pointer;
    }

    .form-check-input:checked[type="checkbox"] {
      background-image: url("../images/profilePage/check-mark.png") !important;
      background-size: contain;
    }

    .form-check-input:checked {
      background-color: white !important;
    }

    .form-check-input:focus {
      border-color: none !important;
      outline: 0;
      box-shadow: none !important;
    }
  }

  .question-success-btn {
    background-color: $secondary;
    border: none;
    color: white;
  }
  .question-close-btn {
    background-color: $primary;
    border: none;
    color: white;
  }
}

.profile-table {
  // border-radius: 10px !important;
  overflow-x: scroll;

  table {
    background-color: #fff;
    border-collapse: collapse;
    white-space: nowrap !important;
    // border: 1px solid $secondary !important;
    width: 100%;
  }
  td,
  th {
    text-align: left;
    padding: 20px 10px;
  }
  th {
    background-color: #FE8901;
    border-right: 3px solid $gray-bg;
    color: white;
    text-align: center;
    font-weight: 400;
  }
  th:last-child {
    border-right: 3px solid $gray-bg;
    // border-radius: 0 0px 10px 0px;
  }
  th:first-child {
    border-left: 3px solid $gray-bg;
    // border-radius: 0 0px 0px 10px;
  }
  td {
    border: 3px solid $gray-bg;
    text-align: center;
    width: auto !important;
    color: #000;
    font-weight: bold;
  }
  tr:nth-child(odd) {
    background-color: #eaeaea;
  }
  .table-register-btn {
    color: #000;
    border: 2.5px solid $secondary;
    background-color: transparent;
    border-radius: 30px;
    padding: 10px 20px;
  }
  .table-register-btn:hover {
    color: #fff;
    background-color: $secondary;
  }
  // .first-row {
  //   border-top: none;
  // }

  .attempt-btn-wrapper {
    min-width: 100px;
  }

  .pagination-btn {
    padding: 10px;
    border: 1px solid $secondary;
    border-radius: 5px;
    background-color: transparent;
    color: white;
  }
}

.profile-course {
  background-color: white;
}
.profile-course-header {
  background-color: #fe8901;
  padding: 20px;
}

.less-top-border {
  border: 2px solid #FE8901;
  border-width: 0 2px 2px 2px;
}

// btn designing for mobile responsive table -- thats why written as independent
.table-register-btn {
  color: #000;
  border: 2.5px solid $secondary;
  background-color: transparent;
  border-radius: 30px;
  padding: 10px 20px;
}
.table-register-btn:hover {
  color: #fff;
  background-color: $secondary;
}

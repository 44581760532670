@import "variables";

@font-face {
  font-family: "TheSansArab-Black";
  src: url("../fonts/TheSansArab-Black.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TheSansArab-Bold";
  src: url("../fonts/TheSansArab-Bold.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TheSansArab-Plain";
  src: url("../fonts/TheSansArab-Plain.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "TheSansArab-Light";
  src: url("../fonts/TheSansArab-Light.woff ") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "TheSansArab-Plain" !important;
  a {
    text-decoration: none !important;
    color: inherit !important;
  }
  button {
    cursor: pointer;
  }
}
body {
  background-color: $gray-bg;
}

.text-purple {
  color: $primary !important;
}

.text-green {
  color: $secondary !important;
}

.bg-purple {
  background-color: $primary !important;
}

.bg-green {
  background-color: $secondary !important;
}

.bg-white {
  background-color: white !important;
}

.border-green {
  border: 1px solid $secondary;
}

.text-orange{
  color: #FE8901 !important
}

.auth-wrapper {
  min-height: 100vh;
  background-image:
  //  linear-gradient(
  //     to bottom,
  //     rgba(8, 2, 7, 0.3),
  //     rgba(8, 2, 7, 0.4)
  //   ),
    url("../images/banner-auth.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: 100px;
}

// .forgot-password-wrapper {
//   background-image: linear-gradient(
//       to bottom,
//       rgba(8, 2, 7, 0.3),
//       rgba(8, 2, 7, 0.4)
//     ),
//     url("../images/landing-bg-mob.png") !important;
//   background-position: center center;
//   background-size: cover;
// }
// .sign-up-wrapper {
//   background-image: linear-gradient(
//       to bottom,
//       rgba(8, 2, 7, 0.3),
//       rgba(8, 2, 7, 0.4)
//     ),
//     url("../images/landing-bg-mob.png") !important;
//   background-position: center center;
//   background-size: cover;
// }

@media (min-width: 600px) {
  .auth-wrapper {
    background-image: 
    // linear-gradient(
    //     to bottom,
    //     rgba(8, 2, 7, 0.3),
    //     rgba(8, 2, 7, 0.4)
    //   ),
      url("../images/banner-auth.png");
    background-size: cover;
  }
  // .forgot-password-wrapper {
  //   background-image: linear-gradient(
  //       to bottom,
  //       rgba(8, 2, 7, 0.3),
  //       rgba(8, 2, 7, 0.4)
  //     ),
  //     url("../images/landing-bg-hi.png");
  // }
  // .sign-up-wrapper {
  //   background-image: linear-gradient(
  //       to bottom,
  //       rgba(8, 2, 7, 0.3),
  //       rgba(8, 2, 7, 0.4)
  //     ),
  //     url("../images/landing-bg-hi.png");
  // }
}

.navbar-shadow {
  box-shadow: 0px 0px 5px 0px #d3d3d3;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  // background-color: #232425bc;
  background-color: #f5f5f5;
  height: 100px;

  .gea-logo {
    width: 90%;
  }

  .hamburger {
    width: 30px !important;
    height: 30px;
    min-width: 30px !important;
    min-height: 30px;
    color: $secondary;
    // color: white;
    cursor: pointer;
  }
  .menu-item {
    font-size: 22px;

    a.active {
      color: $secondary !important;
    }
  }

  .offcanvas {
    background-color: rgba(8, 2, 7, 0.9);
  }
  .offcanvas-backdrop {
    background-color: rgb(75, 74, 74);
    opacity: 0.7;
  }

  .hamburger-body {
    li {
      // display: inline;
      color: $secondary;
      list-style: none;
      // display: inline;
      margin: 0 30px !important;
      font-size: 1.3rem;
      margin: 10px 20px !important;
      padding-bottom: 7px;
      border-bottom: 1.5px solid transparent;

      cursor: pointer;
      transition: 0.2s;
    }
    .sign-up-btn {
      background-color: #00aa2b;
      color: white;
      padding: 5px 10px;
      font-size: 1.5rem;
      border-color: transparent;
      border-radius: 10px;
    }

    .btn-close {
      background-color: $secondary !important;
    }
  }

  .nav-items {
    font-family: "thesans-plain", sans-serif;
    unicode-bidi: bidi-override !important;
    display: inline;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    li {
      list-style: none;
      padding-bottom: 5px;
      color: $dark-gray;
      // color: white;
      font-size: 0.8rem;
      margin: 0 5px;
      border: 1px solid transparent;
      // border-bottom: 2px solid transparent;

      cursor: pointer;
      transition: 0.2s;

      &:hover {
        border: 1px solid $secondary;
      }

      &.active {
        border: 1px solid $secondary;
      }
    }

    @media (min-width: 1200px) {
      li {
        font-size: 1.2rem;
      }
      #sign-in-btn {
        min-width: 120px !important;
        white-space: nowrap;
      }
    }

    button {
      background-color: #00aa2b;
      color: white;
      padding: 5px 10px;
      font-size: 1.1rem;
      border-color: transparent;
      border-radius: 10px;
    }
  }
}

label {
  font-size: 1rem;
  color:#707070;
  margin-bottom: 2px !important;
  margin-top: 15px !important;
}

.input-text {
  border: 1px solid $secondary !important;
  height: 40px;
  border-radius: 5px;
}

.form-container {
  display: flex;
  justify-content: center;
  padding: 3rem 0;

  .form {
    background-color: white;
    padding: 40px 50px;
    border-radius: 10px;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  @media (min-width: 568px) {
    .form {
      width: 75%;
    }
  }
  @media (min-width: 768px) {
    .form {
      width: 60%;
    }
    .signup-form-container {
      width: 700px !important;
    }
  }
  @media (min-width: 998px) {
    .form {
      width: 45%;
    }
  }
  @media (min-width: 1200px) {
    .form {
      width: 35%;
    }
  }

  .main-btn {
    background-color: $secondary;
    color: white;
    padding: 5px 15px;
    border-color: transparent;
    border-radius: 8px;
    font-size: 15px;
    margin-top: 30px;
    box-shadow: 0px 3px 6px #00000029;

    &:hover {
      box-shadow: 0px 3px 6px #00000010;
    }
  }

  @media (min-width: 568px) {
    .main-btn {
      font-size: 20px;
    }
  }

  .auth-bottom-text {
    margin-top: 30px;
    p {
      text-align: center;
      color: $primary;
      font-size: 0.9rem;
      a {
        color: $primary !important;
        text-decoration: none;
        padding-bottom: 2px;
        border-bottom: 1.5px solid $primary;
      }
    }
  }

  @media (min-width: 568px) {
    .auth-bottom-text {
      p {
        font-size: 1.2rem;
      }
    }
  }
}

.register-btn {
  background-color: $secondary;
  color: white;
  padding: 5px 15px;
  border-color: transparent;
  border-radius: 8px;
  font-size: 20px;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    box-shadow: 0px 3px 6px #00000010;
  }
}

.purple-btn {
  background-color: $primary;
  color: white;
  padding: 5px 15px;
  border-color: transparent;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    box-shadow: 0px 3px 6px #00000010;
  }
}

.delete-btn {
  background-color: red;
  color: white;
  padding: 5px 15px;
  border-color: transparent;
  border-radius: 8px;
  font-size: 15px;
  box-shadow: 0px 3px 6px #00000029;

  &:hover {
    box-shadow: 0px 3px 6px #00000010;
  }
}

ol {
  li {
    line-height: 1.5;
  }
}

.note-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.note-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.note-menu {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.note-count {
  background-color: red;
  position: absolute;
  top: 5;
  left: 10;
  z-index: 999;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.rtl-text-truncate {
  direction: rtl !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.unread-note {
  border: 2px solid $primary !important;
  background-color: rgba($primary, 0.8) !important;
  color: #fff !important;

  &:hover {
    background-color: rgba($primary, 1) !important;
    color: #fff;
  }
}

.note-item {
  width: 240px;
  border: 1px solid $secondary;
  background-color: rgba($secondary, 0.6);
  border-radius: 5px;
  margin: 0 20px;
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: $secondary;
    color: #fff;
  }

  p {
    margin-bottom: 0;
  }
}

.download-certificate {
  text-decoration: underline;
  color: #0066cc;
  font-size: 12px;
  cursor: pointer;
}

.hc-logo {
  width: inherit;
}

// @media (max-width: 992px) {
//   .hc-logo {
//     width: 35%;
//   }
// }

// @media (max-width: 768px) {
//   .hc-logo {
//     width: 50%;
//   }
// }

// @media (max-width: 576px) {
//   .hc-logo {
//     width: 60%;
//   }
// }



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.fadeOut{
  opacity:0;
  width:0;
  height:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}

.training-requests-list{
  list-style-position: inside;
}

.response-overlay{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .response-content{
      border: 1px solid #9E9E9E;
      padding: 50px 3px;
      width: 30vw;
    
      .response-icon-1{
        width: 50%;
      }
      
      .response-icon-2{
        width: 50px;
      }
      
      h5{
          color: #9E9E9E;
          width: auto;
      } 

      button{
        margin-top: 5px;
        border: 1px solid #9E9E9E;
        padding: 10px;
      }


      @media (max-width: 992px) {
        width: 40vw;
      }
     
      @media (max-width: 768px) {
        width: 60vw;
        h4, h5{
          font-size: 1.1rem
        }
      }
     
      @media (max-width: 576px) {
        width: 80vw;
      }
    }
}
@import "variables.scss";

.footer-top {
  position: relative;
  border: 1.5px solid #d3d3d3;
  margin: 0 40px;
}
// .footer-top-scroll {
//   position: absolute;
//   top: -25px;
//   left: 48%;
//   right: 48%;
//   width: 50px;
//   height: 50px;
//   background-color: #fff;
//   border: 2px solid #d3d3d3;
//   border-radius: 50%;
//   cursor: pointer;
// }
.footer-top-scroll {
  position: absolute;
  top: -25px;
  left: 48%;
  right: 48%;
  width: 50px;
  height: 50px;
  // background-color: #fff;
  // border: 2px solid #d3d3d3;
  // border-radius: 50%;
  cursor: pointer;
}

@media (min-width: 768px) {
  .footer-top {
    margin: 0 80px;
  }
}
.footer {
  background-color: #fff;

  .footer-logo {
    width: 280px;
    overflow: hidden;
  }
  .footer-logo img {
    width: 280px;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    .footer-logo {
      width: 300px;
    }
    .footer-logo img {
      width: 300px;
    }
  }
  @media (min-width: 998px) {
    .footer-logo {
      width: 320px;
    }
    .footer-logo img {
      width: 320px;
    }
  }

  .footer-divider {
    border: 1px solid rgba($light-gray, 0.5);
    margin: 0 40px;
  }
  @media (min-width: 768px) {
    .footer-divider {
      margin: 0 200px;
    }
  }

  .text-primary-color {
    color: $primary !important;
  }
  .footer-cellno {
    color: $dark-gray;
  }
  // .footer-social-icon {
  //   width: 25px;
  //   height: 25px;
  // }
  .footer-social-icon img {
    width: 25px;
    // height: 25px;
    // object-fit: cover;
  }

  .mail-phone{
      width: 25px;
      height: auto;
  }

  .footer-social-icon .social-icon {
    width: 30px;
  }


  .vision-2030-logo {
    padding-left: 30px;
    width: 150px;
    border-left: 2px solid white;
  }

  .gea-logo {
    margin-left: 0;
    width: 250px;
  }

  .barnamg-logo {
    padding-right: 30px;
    width: 220px;
  }

  .combined-logos {
    width: 400px;
  }

  .social-and-contacts {
    margin-bottom: 100px;
  }

  .social-icons {
    display: flex;
    align-items: center;
  }

  .contact-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .copyright {
    color: #888888;
    font-size: 1.5rem !important;
    font-weight: bold;
  }

  @media (max-width: 1300px) {
    .gea-logo {
      margin-left: 0;
      width: 180px;
    }

    .combined-logos {
      width: 250px;
    }

    .social-icons {
      justify-content: center;
      margin-bottom: 20px;
    }

    .contact-section {
      flex-direction: row;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .vision-2030-logo {
      width: 140px;
    }

    .gea-logo {
      margin-left: 0px;
    }

    .barnamg-logo {
      padding-right: 10px;
      width: 190px;
    }

    .social-icons {
      justify-content: center;
      margin-bottom: 40px;
    }

    .contact-section {
      flex-direction: column;
      justify-content: center;
      span {
        justify-content: center;
      }
    }

    .copyright {
      font-size: 1.2rem !important;
    }
  }

  @media (max-width: 576px) {
    .vision-2030-logo {
      padding-left: 20px;
      width: 140px;
    }

    .gea-logo {
      margin-left: 0px;
      width: 200px;
      margin-bottom: 50px;
      padding-right: 0px;
    }

    .social-and-contacts {
      margin-bottom: 60px;
    }

    .contact-section {
      p {
        font-size: 1rem !important;
        &:first-child {
          margin-bottom: 10px !important;
        }
      }
    }

    .copyright {
      font-size: 1rem !important;
    }
  }

  @media (max-width: 480px) {
    .vision-2030-logo {
      padding-left: 20px;
      width: 140px;
      border-left: 0px;
    }

    .gea-logo {
      margin-top: 20px;
    }
  }
}
